import axios from "axios";

export const MICROSOLUTIONS_URL =
  process.env.REACT_APP_MICROSOLUTIONS_URL + "/auth";
export const GET_USER_BY_ACCESSTOKEN_URL = `${process.env.REACT_APP_API_ENDPOINT}/main/verify_token`;

export function login_using_refresh_token(data) {
  return axios.post(MICROSOLUTIONS_URL + "/login_using_refresh_token", data);
}

export async function switchBusiness(data) {
  return await axios.post(MICROSOLUTIONS_URL + "/login_with_business_token", {
    ...data,
    base_url: process.env.REACT_APP_API_ENDPOINT
  });
}

export async function login(data) {
  return await axios.post(
    MICROSOLUTIONS_URL + "/login_with_username_and_password",
    data
  );
}

export async function getUserByToken() {
  return await axios.get(GET_USER_BY_ACCESSTOKEN_URL);
}
