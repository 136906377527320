import React, { useEffect, useState } from "react";
import "./pages/managePage.css";
import { useParams } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Loader from "./Loader";

function ManagePage() {
  const params = new URLSearchParams(window.location.search);
  const queries = Object.fromEntries(params.entries());

  const [queryData, setQueryData] = useState({});
  const [selectedPreferences, setSelectedPreferences] = useState([]);
  const [hasInteracted, setHasInteracted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(undefined);
  const [showPicklist, setShowPicklist] = useState(false);
  const [unsubReason, setUnsubReason] = useState("");

  useEffect(() => {
    if (hasInteracted) {
      setShowPicklist(selectedPreferences.length === 0);
    }
  }, [selectedPreferences, hasInteracted]);

  useEffect(() => {
    setQueryData(queries);
  }, []);

  // Handler for picklist selection
  const handlePicklistChange = e => {
    setUnsubReason(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!queryData.c_ref) return;
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}/preferences/org/lmgt?c_ref=${queryData.c_ref}`
        );
        if (response.status === 200 && response?.data?.status) {
          setData(response.data.data);
          setSelectedPreferences(
            response.data.data?.leadPreferences.map(
              item => item.Preference_Center__c
            )
          );
          setLoading(false);
        } else {
          setLoading(false);
          toast.error("record not found");
          console.log(response);
          // toast.error("something went wrong");
        }
        // setData(response.data);
      } catch (err) {
        toast.error("record not found");
        setLoading(false);
        console.log(err);
        // setError(err);
      }
    };

    fetchData();
  }, [queryData.c_ref]);

  const handleSubmit = async e => {
    e.preventDefault();
    if (hasInteracted && !selectedPreferences.length && unsubReason === "")
      return toast.warn("please select a reason");

    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/preferences/org/lmgt`,
        {
          leadId: data.lead.id,
          selectedPreferences,
          reason: selectedPreferences.length === 0 ? unsubReason : null,
          preferenceCenters: data.preferenceCenters,
          leadPreferences: data.leadPreferences.map(ele => ele.Id)
        }
      );
      if (response.status === 200 && response?.data?.status) {
        setLoading(false);
        window.location.href = "/manage/pref/success";
      } else {
        setLoading(false);
        toast.error("something went wrong");
      }
      // setData(response.data);
    } catch (err) {
      setLoading(false);
      toast.error("something went wrong");
      console.log(err);
      // setError(err);
    }
  };

  // Handler for checkbox toggle
  const handleCheckboxChange = id => {
    setHasInteracted(true);
    setSelectedPreferences(
      prevSelected =>
        prevSelected.includes(id)
          ? prevSelected.filter(prefId => prefId !== id) // Remove ID if already selected
          : [...prevSelected, id] // Add ID if not selected
    );
  };

  return (
    <>
      <ToastContainer />
      {loading && !data ? (
        <Loader />
      ) : (
        <>
          {data && (
            <div
              className="main-container"
              style={{
                backgroundColor: "#232323",
                color: "white",
                borderTop: "0px",
                paddingTop: "5px",
                paddingBottom: "30px"
              }}
            >
              <div className="content">
                <br />
                <span
                  style={{
                    color: "#f5f5f5",
                    fontFamily: "Noto Sans, Arial,sans-serif",
                    fontSize: "28px",
                    fontWeight: "500",
                    lineHeight: "38px"
                  }}
                >
                  Update Your Email Preferences
                </span>
                <br />
                <br />
                <span
                  style={{
                    color: "#ffffff",
                    fontFamily: "Noto Sans, Arial,sans-serif",
                    fontSize: "14px"
                  }}
                >
                  Email Address:{" "}
                  <span id="current-email">{data?.lead?.email}</span>
                </span>
                &nbsp;
                <br />
                <img
                  alt="EPIX NOW"
                  src="https://appboy-images.com/appboy/communication/assets/image_assets/images/5c8913ba733b1606ca3aea2b/original.png?1552487354"
                  style={{
                    width: "111px",
                    height: "4px"
                    // paddingTop: "16px",
                    // paddingBottom: "10px"
                  }}
                />
                <span
                  className="mobile"
                  style={{
                    color: "#f5f5f5",
                    fontFamily: "Noto Sans, Arial,sans-serif",
                    fontSize: "14px",
                    fontWeight: "normal",
                    lineHeight: "22px"
                  }}
                >
                  <form>
                    {data?.preferenceCenters?.map(preference => (
                      <div key={preference.Id}>
                        <input
                          type="checkbox"
                          name="managePrefs"
                          checked={selectedPreferences.includes(preference.Id)}
                          onChange={e => handleCheckboxChange(preference.Id)}
                          value={preference.Id}
                          className="checkbox-style"
                        />
                        <span
                          style={{
                            color: "#f5f5f5",
                            fontSize: "16px",
                            lineHeight: "35px"
                          }}
                        >
                          &nbsp;
                          <b>{preference.Name}</b>
                        </span>
                        <br />
                      </div>
                    ))}
                    {/* Show picklist if no checkboxes are selected */}
                    {showPicklist && (
                      <div>
                        <label
                          style={{
                            color: "#f5f5f5",
                            fontFamily: "Noto Sans, Arial, sans-serif",
                            fontSize: "16px",
                            lineHeight: "35px"
                          }}
                        >
                          Reason for Unsubscribing:
                        </label>
                        <select
                          value={unsubReason}
                          onChange={handlePicklistChange}
                          style={{
                            width: "300px",
                            height: "35px",
                            background: "#b2b2b2",
                            color: "#232323",
                            fontFamily: "Noto Sans, Arial, sans-serif",
                            fontSize: "14px",
                            border: "none",
                            marginTop: "10px",
                            cursor: "pointer"
                          }}
                        >
                          <option value="">Select a reason</option>
                          <option value="I'm no longer Interested">
                            I'm no longer Interested
                          </option>
                          <option value="This content is not relevant to me">
                            This content is not relevant to me
                          </option>
                          <option value="This content is not relevant to me">
                            This content is not relevant to me
                          </option>
                          <option value="This is spam/I never subscribed to this email list">
                            This is spam/I never subscribed to this email list
                          </option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                    )}
                    {/* <span
                      style={{
                        fontFamily: "Noto Sans, Arial,sans-serif"
                      }}
                    >
                      <input
                        type="submit"
                        value="Update"
                        onClick={handleSubmit}
                      />
                    </span> */}
                    <button
                      onClick={handleSubmit}
                      disabled={loading || !hasInteracted} // Disable the button while loading
                      style={{
                        backgroundColor: "#c7a338",
                        width: "172px",
                        height: "48px",
                        fontFamily: "Noto Sans",
                        fontSize: "14px",
                        fontWeight: "bold",
                        lineHeight: "1.71",
                        textAlign: "center",
                        border: "none",
                        color: "#232323",
                        padding: "12px",
                        cursor: loading ? "not-allowed" : "pointer", // Change cursor while loading
                        marginTop: "30px"
                      }}
                    >
                      {loading ? (
                        <span>
                          <div
                            className="spinner"
                            style={{
                              width: "20px",
                              height: "20px",
                              border: "3px solid #f3f3f3",
                              borderTop: "3px solid #c7a338",
                              borderRadius: "50%",
                              animation: "spin 1s linear infinite",
                              display: "inline-block",
                              marginRight: "10px"
                            }}
                          ></div>
                          Updating...
                        </span>
                      ) : (
                        "Update"
                      )}
                    </button>
                  </form>
                </span>
                <br />
                <span style={{ color: "#f5f5f5" }}></span>
                <br />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default ManagePage;
