import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { accountSlice } from "../app/modules/Accounts/_redux/accountSlice";
import { contactSlice } from "../app/modules/Contacts/_redux/contactSlice";
import { preferenceSlice } from "../app/modules/Preferences/_redux/preferenceSlice";
import { userTokenSlice } from "../app/modules/Leads/_redux/userToken/userTokenSlice";
import { leadSlice } from "../app/modules/Leads/_redux/lead/leadSlice";
import { settingsSlice } from "../app/modules/Settings/_redux/settingsSlice";
import { campaignSlice } from "../app/modules/Campaign/_redux/campaignSlice";
import { leadPreferenceSlice } from "../app/modules/Leads/_redux/leadPreference/leadPreferenceSlice";
//case
import { categorysSlice } from "../app/case/Category/_redux/categorys/categorysSlice";
import { solutionsSlice } from "../app/case/Solution/_redux/solutions/solutionsSlice";
import { casetypesSlice } from "../app/case/Casetype/_redux/casetypes/casetypesSlice";
import { commentsSlice } from "../app/case/Case/_redux/comments/commentsSlice";
import { generalSlice } from "../app/case/General/_redux/generalSlice";
import { staffsSlice } from "../app/case/General/_redux/staffs/staffsSlice";
import { casesSlice } from "../app/case/Case/_redux/cases/casesSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  account: accountSlice.reducer,
  campaign: campaignSlice.reducer,
  contact: contactSlice.reducer,
  lead: leadSlice.reducer,
  leadPreference: leadPreferenceSlice.reducer,
  userToken: userTokenSlice.reducer,
  preference: preferenceSlice.reducer,
  settings: settingsSlice.reducer,
  categorys: categorysSlice.reducer,
  solutions: solutionsSlice.reducer,
  casetypes: casetypesSlice.reducer,
  general: generalSlice.reducer,
  staffs: staffsSlice.reducer,
  cases: casesSlice.reducer,
  comments: commentsSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
