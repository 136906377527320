import { createSlice } from "@reduxjs/toolkit";

const initialCampaignState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  campaignForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const campaignSlice = createSlice({
  name: "campaign",
  initialState: initialCampaignState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getCampaignById
    campaignFetched: (state, action) => {
      state.actionsLoading = false;
      state.campaignForEdit = action.payload.campaignForEdit;
      state.error = null;
    },
    // findCampaign
    campaignsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    dashboardFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.dashboard = action.payload.dashboard;
    },
    // dashboardFetched
    // createCampaign
    campaignCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.campaign);
    },
    // updateCampaign
    campaignUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.campaign.id) {
          return action.payload.campaign;
        }
        return entity;
      });
    },
    // deleteCampaign
    campaignDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteCampaign
    campaignsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // campaignUpdateState
    campaignStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
